import { defineAsyncComponent } from 'vue';

export function registerGlobalComponents(app) {
    app.component(
        'TaskCard',
        defineAsyncComponent(() => import('@/components/tasks/taskCard/TaskCard.vue'))
    );
    app.component(
        'CRMAccount',
        defineAsyncComponent(() => import('@/components/crm/company/companyCard/CompanyCard.vue'))
    );
    app.component(
        'CRMContact',
        defineAsyncComponent(() => import('@/components/crm/contacts/contactsCard/ContactCard.vue'))
    );
    app.component(
        'CRMLead',
        defineAsyncComponent(() => import('@/components/crm/lead/leadCard/LeadCard.vue'))
    );
    app.component(
        'CRMDeal',
        defineAsyncComponent(() => import('@/components/crm/deal/dealCard/DealCard.vue'))
    );
    app.component(
        'UserInformation',
        defineAsyncComponent(() => import('@/components/user/userInformation/UserInformation.vue'))
    );
    app.component(
        'CustomElement',
        defineAsyncComponent(() => import('@/components/dynamicForm/components/formBuilder/formViewer/CustomElement.vue'))
    );
    app.component(
        'RenderCustomElement',
        defineAsyncComponent(() => import('@/components/dynamicForm/components/formRenderer/CustomElement.vue'))
    );
    app.component(
        'EntityForm',
        defineAsyncComponent(() => import('@/pages/manage/EntityForm.vue'))
    );
    app.component(
        'DynamicForm',
        defineAsyncComponent(() => import('@/components/dynamicForm/DynamicForm.vue'))
    );
    app.component(
        'CustomFormCard',
        defineAsyncComponent(() => import('@/pages/forms/CustomFormCard.vue'))
    );
    app.component(
        'CRMActivities',
        defineAsyncComponent(() => import('@/components/crm/components/instanceActivity/components/activityCard/ActivityCard.vue'))
    );
    app.component(
        'CreateShortTask',
        defineAsyncComponent(() => import('@/components/tasks/taskCard/CreateShortTask.vue'))
    );
    app.component(
        'DefaultEntityCard',
        defineAsyncComponent(() => import('@/components/entities/DefaultEntityCard.vue'))
    );
    app.component(
        'EntityCard',
        defineAsyncComponent(() => import('@/components/entities/EntityCard.vue'))
    );
    app.component(
        'DefaultCreateEntityCard',
        defineAsyncComponent(() => import('@/components/entities/DefaultCreateEntityCard.vue'))
    );
    app.component(
        'BugReportForm',
        defineAsyncComponent(() => import('@/components/bugReport/BugReportForm.vue'))
    );
    app.component(
        'TaskTemplates',
        defineAsyncComponent(() => import('@/components/tasks/taskTemplates/TaskTemplates.vue'))
    );
    app.component(
        'TaskLoyalty',
        defineAsyncComponent(() => import('@/components/tasks/taskLoyalty/TaskLoyalty.vue'))
    );
    app.component(
        'AutomationCard',
        defineAsyncComponent(() => import('@/components/automation/automation/automationCard/AutomationCard.vue'))
    );
    app.component(
        'BaseChart',
        defineAsyncComponent(() => import('@/pages/dashboards/charts/BaseChart.vue'))
    );
    app.component(
        'BaseTableWidget',
        defineAsyncComponent(() => import('@/components/dynamicForm/components/formRenderer/components/widgets/fieldWidget/tableWidget/BaseTableWidget.vue'))
    );
    app.component(
        'ChartWidget',
        defineAsyncComponent(() => import('@/pages/dashboards/charts/ChartWidget.vue'))
    );
    app.component(
        'ProjectCard',
        defineAsyncComponent(() => import('@/components/tasks/projectCard/ProjectCard.vue'))
    );
    app.component(
        'WidgetCreateCard',
        defineAsyncComponent(() => import('@/pages/dashboards/charts/ui/WidgetCreateCard.vue'))
    );
    app.component(
        'NewProjectCard',
        defineAsyncComponent(() => import('@/components/tasks/projectCard/NewProjectCard.vue'))
    );
    app.component(
        'menuContainer',
        defineAsyncComponent(() => import('@/components/ui/mobileUI/MenuContainer.vue'))
    );
    app.component(
        'projectContainer',
        defineAsyncComponent(() => import('@/components/ui/mobileUI/projects.vue'))
    );
    app.component(
        'LoyaltyProgram',
        defineAsyncComponent(() => import('@/components/loyaltyProgram/LoyaltyProgram.vue'))
    );
    app.component(
        'LinkEntity',
        defineAsyncComponent(() => import('@/components/ui/LinkEntities/LinkEntity.vue'))
    );
}
