import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import { useRouterStore } from '@/stores/routerStore';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            template: 'MainTemplate',
            public: true,
            //alias: '/feed',
            accessLevel: 'user'
        }
    },
    {
        path: '/news',
        name: 'News',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            template: 'MainTemplate',
            public: true,
            //alias: '/feed',
            accessLevel: 'user'
        }
    },
    {
        path: '/loyalty/',
        name: 'loyaltyProgram',
        component: () => import(/* webpackChunkName: "home" */ '../components/loyaltyProgram/LoyaltyProgram.vue'),
        meta: {
            template: 'MainTemplate',
            public: true,
            accessLevel: 'user'
        },
        children: [
            {
                path: 'store',
                name: 'store',
                props: true,
                component: () => import(/* webpackChunkName: "contacts" */ '../components/loyaltyProgram/components/Store/TheStore.vue'),
                meta: {
                    template: 'MainTemplate',
                    public: true,
                    accessLevel: 'user'
                }
            },
            {
                path: 'ideas',
                name: 'ideas',
                props: true,
                component: () => import(/* webpackChunkName: "contacts" */ '../components/loyaltyProgram/components/Ideas/TheIdeas.vue'),
                meta: {
                    template: 'MainTemplate',
                    public: true,
                    accessLevel: 'user'
                }
            },
            {
                path: 'purchases',
                name: 'purchases',
                props: true,
                component: () => import(/* webpackChunkName: "contacts" */ '../components/loyaltyProgram/components/Purchases/ThePurchases.vue'),
                meta: {
                    template: 'MainTemplate',
                    public: true,
                    accessLevel: 'user'
                }
            },
            {
                path: 'admin',
                name: 'admin',
                props: true,
                component: () => import(/* webpackChunkName: "contacts" */ '../components/loyaltyProgram/components/AdminPage/AdminPage.vue'),
                meta: {
                    template: 'MainTemplate',
                    public: false,
                    accessLevel: ['admin', 'loyalty_admin']
                }
            }
        ]
    },
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '../pages/account/AccountPage.vue'),
        meta: {
            template: 'MainTemplate',
            public: false,
            title: 'Account',
            hasContentLeftPanel: true,
            accessLevel: 'user'
        }
    },
    {
        path: '/user/login',
        name: 'Login',
        meta: {
            template: 'LandingTemplate',
            public: true,
            accessLevel: null
        },
        component: () => import(/* webpackChunkName: "login" */ '../pages/auth/LoginPage.vue')
    },
    {
        path: '/user/register',
        name: 'Register',
        meta: {
            template: 'LandingTemplate',
            public: true,
            accessLevel: null
        },
        component: () => import(/* webpackChunkName: "Register" */ '../pages/auth/RegisterPage.vue')
    },
    {
        path: '/user/forgot_password',
        name: 'ForgotPassword',
        meta: {
            template: 'LandingTemplate',
            public: true,
            accessLevel: null
        },
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '../pages/auth/ForgotPassword.vue')
    },
    {
        path: '/password_recovery',
        name: 'ResetPassword',
        meta: {
            template: 'LandingTemplate',
            public: true,
            accessLevel: null
        },
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '../pages/auth/PasswordReset.vue')
    },
    {
        path: '/blogs',
        name: 'Blogs',
        meta: {
            template: 'MainTemplate',
            public: true,
            accessLevel: 'user'
        },
        component: () => import(/* webpackChunkName: "Blogs" */ '../pages/blog/BlogsPage.vue')
    },
    {
        path: '/crm/tasks',
        name: 'tasks',
        props: true,
        component: () => import(/* webpackChunkName: "tasks" */ '../pages/tasks/taskListRefact.vue'),
        meta: {
            template: 'MainTemplate',
            public: false,
            title: 'Tasks',
            hasContentLeftPanel: true,
            accessLevel: 'user'
        }
    },
    {
        path: '/crm/dashboard',
        name: 'dashboard',
        props: true,
        component: () => import(/* webpackChunkName: "tasks" */ '../pages/dashboards/Dashboard.vue'),
        meta: {
            template: 'MainTemplate',
            public: false,
            title: 'Dashboard',
            accessLevel: 'user'
        }
    },
    {
        path: '/crm/task/:id',
        name: 'task',
        props: true,
        component: () => import(/* webpackChunkName: "task" */ '../pages/tasks/singleTask.vue'),
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        }
    },
    {
        path: '/crm/contact/:id',
        name: 'contact',
        props: true,
        component: () => import(/* webpackChunkName: "contact" */ '../pages/crm/contacts/SingleContact.vue'),
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        }
    },
    {
        path: '/crm/opportunity/:id',
        name: 'deal',
        props: true,
        component: () => import(/* webpackChunkName: "deal" */ '../pages/crm/deals/SingleDeal.vue'),
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        }
    },
    {
        path: '/crm/lead/:id',
        name: 'lead',
        props: true,
        component: () => import(/* webpackChunkName: "lead" */ '../pages/crm/leads/SingleLead.vue'),
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        }
    },
    {
        path: '/crm/account/:id',
        name: 'crmAcount',
        props: true,
        component: () => import(/* webpackChunkName: "account" */ '../pages/crm/company/SingleCompany.vue'),
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        }
    },
    {
        path: '/crm',
        name: 'crm',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            hasContentLeftPanel: true,
            icon: '',
            disableBreadcrum: true,
            accessLevel: 'user'
        },
        children: [
            {
                path: '/crm/contacts',
                name: 'contacts',
                props: true,
                component: () => import(/* webpackChunkName: "contacts" */ '../pages/crm/contacts/ContactsList.vue'),
                meta: {
                    template: 'MainTemplate',
                    public: false,
                    hasContentLeftPanel: true,
                    accessLevel: 'user'
                }
            },
            {
                path: '/crm/accounts',
                name: 'company',
                props: true,
                component: () => import(/* webpackChunkName: "accounts" */ '../pages/crm/company/CompanyList.vue'),
                meta: {
                    template: 'MainTemplate',
                    public: false,
                    hasContentLeftPanel: true,
                    accessLevel: 'user'
                }
            },
            {
                path: '/crm/leads',
                name: 'leads',
                props: true,
                component: () => import(/* webpackChunkName: "leads" */ '../pages/crm/leads/LeadsList.vue'),
                meta: {
                    template: 'MainTemplate',
                    public: false,
                    hasContentLeftPanel: true,
                    accessLevel: 'user'
                }
            },
            {
                path: '/crm/opportunities',
                name: 'deals',
                props: true,
                component: () => import(/* webpackChunkName: "deals" */ '../pages/crm/deals/DealsList.vue'),
                meta: {
                    template: 'MainTemplate',
                    public: false,
                    hasContentLeftPanel: true,
                    accessLevel: 'user'
                }
            },
            {
                path: '/crm/activities',
                name: 'activities',
                props: true,
                component: () => import(/* webpackChunkName: "deals" */ '../pages/crm/activities/ActivitiList.vue'),
                meta: {
                    template: 'MainTemplate',
                    public: false,
                    hasContentLeftPanel: true,
                    accessLevel: 'user'
                }
            }
        ]
    },

    {
        path: '/components',
        name: 'components',
        props: true,
        component: () => import(/* webpackChunkName: "components" */ '../pages/Components.vue'),
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        }
    },
    {
        path: '/external',
        name: 'external',
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        },
        component: () => import(/* webpackChunkName: "external" */ '../pages/ExternalPage.vue')
    },
    {
        path: '/algorithms',
        name: 'algorithms',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        },
        component: () => import(/* webpackChunkName: "algorithms" */ '../pages/Algorithms.vue')
    },
    {
        path: '/calendar',
        name: 'calendarFull',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        },
        component: () => import(/* webpackChunkName: "calendar" */ '../pages/calendar/CalendarPage.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user',
            hasContentLeftPanel: true
        },
        component: () => import(/* webpackChunkName: "calendar" */ '../pages/dashboards/Dashboard.vue')
    },
    {
        path: '/calendar/:view',
        name: 'calendarCustomView',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        },
        component: () => import(/* webpackChunkName: "calendar" */ '../pages/calendar/CalendarPage.vue')
    },
    {
        path: '/calendar/:view/:year/:month:/:day',
        name: 'calendarCustom',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        },
        component: () => import(/* webpackChunkName: "calendar" */ '../pages/calendar/CalendarPage.vue')
    },
    {
        path: '/orgchart',
        name: 'orgchart',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        },
        component: () => import(/* webpackChunkName: "orgchart" */ '../pages/OrgChart.vue')
    },
    {
        path: '/page/:id',
        name: 'page',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        },
        component: () => import(/* webpackChunkName: "dynamicpage" */ '../pages/DynamicPage.vue')
    },
    {
        path: '/automations',
        name: 'automations',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'admin'
        },
        component: () => import(/* webpackChunkName: "dynamicpage" */ '../pages/automation/AutomationList.vue')
    },
    {
        path: '/automation/:id',
        name: 'automation',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'admin'
        },
        component: () => import(/* webpackChunkName: "dynamicpage" */ '../pages/automation/AutomationPage.vue')
    },
    {
        path: '/manage/:id',
        name: 'manageEntityForm',
        props: true,
        component: () => import(/* webpackChunkName: "manage-entity-form" */ '../pages/manage/EntityForm.vue'),
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'admin'
        }
    },
    {
        path: '/forms',
        name: 'forms',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'admin'
        },
        component: () => import(/* webpackChunkName: "forms" */ '../pages/forms/FormList.vue')
    },
    {
        path: '/form/:formId',
        name: 'customForm',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user'
        },
        component: () => import(/* webpackChunkName: "customForm" */ '../pages/forms/CustomForm.vue')
    },
    {
        path: '/manage/entitys',
        name: 'entitysList',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'admin'
        },
        component: () => import(/* webpackChunkName: "entitysList" */ '../pages/manage/EntitysList.vue')
    },
    {
        path: '/entities/:entity_id',
        name: 'defaultEntitiesList',
        props: true,
        meta: {
            template: 'MainTemplate',
            public: false,
            accessLevel: 'user',
            hasContentLeftPanel: false
        },
        component: () => import(/* webpackChunkName: "entitysList" */ '../pages/entities/DefaultEntitiesList.vue')
    },
    {
        path: '/manage/form-builder',
        name: 'formBuilder',
        props: true,
        component: () => import(/* webpackChunkName: "formBuilder" */ '../pages/manage/FormBuilderPage.vue')
    },
    // {
    //   path: '/manage/entity/:id',
    //   name: 'entity',
    //   props: true,
    //   component: () => import(/* webpackChunkName: "entity" */ '../views/manage/Entity.vue')
    // },
    // {
    //   path: '/entity/:id',
    //   name: 'entitysViews',
    //   props: true,
    //   component: () => import(/* webpackChunkName: "entitysList" */ '../views/entitys/EntitysList.vue')
    // },
    // {
    //   path: '/:entityId/:id/view',
    //   name: 'entitysInstanceView',
    //   props: true,
    //   component: () => import(/* webpackChunkName: "entitysInstance" */ '../views/entitys/EntityInstance.vue')
    // },
    // {
    //   path: '/:entityId/:id/edit',
    //   name: 'entitysInstanceEdit',
    //   props: true,
    //   component: () => import(/* webpackChunkName: "entitysInstance" */ '../views/entitys/EntityInstance.vue')
    // },
    {
        path: '/404',
        name: 'PageNotFound',
        meta: {
            template: 'LandingTemplate',
            public: true,
            title: 'Not found',
            accessLevel: null
        },
        component: () => import(/* webpackChunkName: "404" */ '../pages/PageNotFound.vue')
    },
    { path: '/:pathMatch(.*)*', redirect: { name: 'PageNotFound' } }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
router.beforeEach((to, from) => {
    const routerStore = useRouterStore();
    routerStore.loadRoutParams();
    if (to.meta.title) {
        document.title = `SUPPA | ${to.meta.title}`;
    }

    const publicPages = ['/user/login', '/user/register', '/user/forgot_password', '/password_recovery'];
    const authRequired = !publicPages.includes(to.path);
    const userStore = useUserStore();

    if (!to?.meta?.public && to?.meta?.accessLevel === 'admin' && !userStore.isAdmin) {
        router.replace({ path: '/404' });
    }

    if (to.path === '/loyalty/admin' && !(userStore.isAdmin || userStore.isLoyaltyAdmin)) {
        router.replace({ path: '/404' });
    }

    if (authRequired && !userStore.isAuthorize) {
        userStore.returnUrl = to.fullPath;
        return '/user/login';
    }
    if (!authRequired && userStore.isAuthorize) {
        router.replace({ path: '/' });
    }
    if (from?.name) {
        routerStore.addRoutParams(from.name, from.fullPath);
    }
    if (from.matched?.length > 1) {
        routerStore.addRoutParams(from.matched[0].name, from.fullPath);
    }
    if (to?.query && Object.keys(to.query)?.length === 0 && ['tasks', 'crm', 'contacts', 'company', 'leads', 'deals', 'activities'].includes(to.name)) {
        const savedParams = routerStore.getRoutParams(to.name);
        if (savedParams && savedParams !== from.fullPath && savedParams !== to.fullPath) {
            router.replace(savedParams);
            return;
        }
    }
    if (to.name === 'crm') {
        router.replace('/crm/leads?filter=all_my_active');
    }
});

export default router;
