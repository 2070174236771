<template>
    <div class="search-form__container flex flex-col h-full">
        <div class="search-form__header" style="box-shadow: 0px 2px 15px 0px #0000001a">
            <div class="search-form__search-field">
                <div class="p-4">
                    <BaseInputStyled
                        v-model:value="searchQuery"
                        :placeholder="$t('inputs.enterSearchValues')"
                        ref="searchInput"
                        autofocus
                        :debounceTime="1000"
                        @keyup.esc="this.$emit('close')"
                        :showResetButton="true"
                        :placeholderHideNotEmpty="true"
                        variant="underline"
                        iconName="search-01" />
                </div>
                <div class="search-form__entities px-4 h-[30px]" v-if="data">
                    <FilterGroups :items="filterGroupsItems" @change="value => (selectedFilterGroup = value)" :modalWidth="props.modalWidth - 34" />
                </div>
            </div>
        </div>
        <div v-if="data" class="founded-entities__wrapper h-full relative scroll-wrap" :class="{ 'opacity-50 pointer-events-none': showLoader }">
            <div v-if="showLoader" class="loader absolute h-full w-full flex items-center justify-center">
                <base-loader v-show="showLoader" />
            </div>
            <div v-if="data && data?.every(obj => obj.items.length === 0)" class="p-10 flex flex-col gap-4 items-center justify-center grow">
                <img src="@/assets/images/Search.png" alt="Search" />
                <div class="flex flex-col text-[#979BB2] text-center leading-6">
                    <p>
                        {{ $t('labels.thereAreNoResultsForThisValue') }}
                    </p>
                    <p>
                        {{ $t('labels.tryChangingYourSearchQuery') }}
                    </p>
                </div>
            </div>
            <div
                v-else
                class="founded-entities__container flex flex-col"
                v-for="entity in data.filter(el => selectedFilterGroup === 'all' || el.entity_id === selectedFilterGroup)"
                :key="entity.entity_id">
                <p
                    v-if="selectedFilterGroup === 'all'"
                    class="founded-entities__group-label font-medium text-[#131416] pt-3.5 pb-0.5 px-4 leading-6 border-b border-b-[#EAEBEE] cursor-default select-none">
                    {{ entity.title }}
                </p>
                <InstanceElement v-for="item in entity.items" :key="item.id" @click="onClickItem(entity.entity_id, item?.id)" :item="item" :entityName="entity.entity_name" />
            </div>
        </div>
    </div>
</template>

<script setup>
import BaseInputStyled from '@/components/ui/BaseInputStyled.vue';
import { ref, watch, computed, getCurrentInstance } from 'vue';
import BaseLoader from '@/components/ui/BaseLoader.vue';
import FilterGroups from './components/FilterGroups.vue';
import InstanceElement from './components/InstanceElement.vue';
import { useEntitysStore } from '@/stores/entityStore';
const { getEntityCard } = useEntitysStore();
import EntityApi from '@/api/entity';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const instance = getCurrentInstance();
const modal = instance.appContext.config.globalProperties.$modal;

const props = defineProps({
    entitiesToSearch: {
        type: Array,
        default: () => []
    },
    modalWidth: {
        type: Number,
        default: 551
    }
});

const emit = defineEmits(['close']);

const searchQuery = ref('');
let madeRequest = ref(false);
const showLoader = ref(false);
const data = ref(null);
const selectedFilterGroup = ref('all');

const filterGroupsItems = computed(() => [
    ...(data.value
        ? [
              {
                  id: 'all',
                  label: t('labels.allResults'),
                  active: selectedFilterGroup.value === 'all',
                  count: data.value ? data.value.reduce((acc, el) => acc + el.items.length, 0) : 0
              },
              ...data.value.reduce(
                  (acc, el) => [
                      ...acc,
                      {
                          id: el.entity_id,
                          label: el.title,
                          active: selectedFilterGroup.value === el.entity_id,
                          count: el.items.length
                      }
                  ],
                  []
              )
          ]
        : [])
]);

watch(
    () => searchQuery.value,
    () => {
        if (!searchQuery.value || searchQuery.value?.length < 3) {
            madeRequest.value = false;
            return;
        }
        sendRequest(searchQuery.value);
    }
);

async function sendRequest(query) {
    if (query?.length < 3) return;
    madeRequest.value = true;
    showLoader.value = true;

    const activityTypeCondition = {
        operator: 'and',
        type: 'conjunction',
        conditions: [
            {
                field: 'activity_type',
                comparator: 'not in list',
                value: ['Task', 'Comment'],
                type: 'condition'
            }
        ]
    };

    const searchParams = {
        entities: props.entitiesToSearch,
        fields: {
            id: {},
            name: {},
            photo_url: {},
            activity_type: {},
            subject: {}
        },
        filter: activityTypeCondition
    };
    const res = await EntityApi.globalSearch(query, searchParams);
    res ? (data.value = res) : (data.value = []);
    madeRequest.value = false;
    showLoader.value = false;
}

function onClickItem(entityId, id) {
    const cardName = getEntityCard(entityId);
    let params = {
        id: id,
        name: cardName,
        entityId: entityId,
        system: entityId === 'CRMActivities' ? true : false,
        props: {
            id: id
        },
        events: {}
    };
    if (entityId === 'SmartUser') {
        params.props.userId = id;
    }
    modal.open(params);
    emit('close');
}
</script>
